<template>
    <div class="sim-popup">

        <button ref="popupOpener"
                class="sim-popup__button"
                type="button"
                :aria-label="processedAriaLabel"
                @click="isPopupOpen = !isPopupOpen"
        >

            <IconPlus size="sm" />

        </button>

        <LazyUiPopupCard
            v-if="wasPopupMounted && $refs.popupOpener"
            ref="popupEl"
            v-model="isPopupOpen"
            class="sim-popup__bubble"
            :class="containerClass"
            tip-location="bottom-left"
            transition="slide-up"
            alternate
            :ignore-elements="[$refs.popupOpener as HTMLElement]"
        >
            <slot />
        </LazyUiPopupCard>

    </div>
</template>

<script lang="ts" setup>
import type { MaybeElementRef } from '@vueuse/core'

const {
    ariaLabel,
    containerClass,
} = defineProps<{
    ariaLabel?: string | string[]
    containerClass?: string
}>()

const popupEl = ref<MaybeElementRef>(null)
const popupOpener = ref<MaybeElementRef>(null)
const { is: isPopupOpen, was: wasPopupMounted } = useLazyMount()

const processedAriaLabel = computed(() => {
    if (!ariaLabel) return undefined

    if (typeof ariaLabel === 'string') return ariaLabel

    return isPopupOpen.value ? ariaLabel[1] : ariaLabel[0]
})

// DX ATTRIBUTE CHECKS -----------------------------------------------------

if (import.meta.dev) {
    // aria-label array doesn't have 2 elements
    if (Array.isArray(ariaLabel) && ariaLabel.length !== 2) {
        throw new Error(
            'If the "aria-label" prop is an array, it must have exactly 2 elements. [open_label, close_label] in <UIPopupComponent>'
        )
    }
}

</script>

<style lang="scss" scoped>

.sim-popup__button {
    $size: 1.25rem;

    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    width: $size;
    height: $size;

    border-radius: 4 * $size;
    outline-offset: 10px;

    color: $sim-c-black;
    background-color: white;
    //filter: drop-shadow(0 2px 2px rgb(0 0 0 / 15%));
    box-shadow: 0 2px 2px rgb(0 0 0 / 35%);

    border: none;
    cursor: pointer;

    isolation: isolate;

    $static-before-transform: translate(-50%, -50%);
    transition: transform $sim-trans-time-normal $sim-timing;

    z-index: 1;

    &::before {
        content: '';
        position: absolute;

        width: 200%;
        height: 200%;

        border-radius: 8 * $size;
        top: 50%;
        left: 50%;
        transform: $static-before-transform;
        transition: transform $sim-trans-time-long $sim-timing;

        background-color: rgba(255 255 255 / 40%);
        z-index: -1;
    }

    &:hover {
        transform: scale(1.1);

        &::before {
            transform: $static-before-transform scale(0.85);
        }
    }
}

.sim-popup__bubble {
    position: absolute;
    top: -1.5rem;
    //left: -0.75rem;

    transform: translate(-10%, -100%);
}

</style>
